@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Esconde a barra de rolagem em navegadores modernos */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;  /* Internet Explorer e Edge */
  scrollbar-width: none;  /* Firefox */
}


/* MARCADORES */
.p-timeline-event-opposite {
    display: none !important; /* Remove a exibição do opposite */
  }


.custom-marker {
  width: 30px;
  height: 30px;
  background-color: #2196F3; /* Ou qualquer cor desejada */
  border-radius: 50%;
  position: absolute;
  top: -15.05%;  /* Centraliza verticalmente */
  transform: translateX(12vw);
}

.custom-connector {
    background-color: #43BCFF; /* Azul */
    width: 2px; /* Espessura da linha */
    opacity: 0.5; 
    height: 100%; /* Conecta todos os eventos */
}

.custom-connector-horizontal {
    background-color: #43BCFF; /* Azul */
    height: 2px; /* Espessura da linha (horizontal) */
    opacity: 0.5; 
    width: 100%; /* Estende a linha horizontalmente */
    position: absolute;
    left: 24vw; /* Ponto central */
    top: -10%;  /* Centraliza verticalmente */
    transform: translateX(-50%); /* Ajusta para alinhar corretamente */
}